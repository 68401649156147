<template>
  <div>
    <div class="header pb-3 d-flex align-items-center">
      <!-- Mask -->
    </div>
    <b-container fluid>
      <b-row>
        <b-col sm="12" xl="8">
          <b-card no-body>
            <b-card-body>
              <h1>My Hackathon Profile</h1>
              <h3>
                {{ user.first_name }} {{ user.last_name }}
                <router-link :to="{ name: 'Profile' }"
                  ><i class="fas fa-edit"></i
                ></router-link>
              </h3>
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(submitForm)">
                  <b-row>
                    <b-col>
                      <html-editor
                        label="Your bio"
                        :pill="true"
                        class="mb-3"
                        name="bio"
                        :placeholder="'Test'"
                        v-model="form.bio"
                        :disabled="$apollo.loading"
                      >
                      </html-editor>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="11">
                      <base-input
                        label="Your Skills*"
                        :rules="{ required: true }"
                        name="Skills"
                      >
                        <el-select
                          v-model="form.skills"
                          multiple
                          filterable
                          placeholder="   Select your skills, start typing"
                          name="skills"
                          default-first-option
                          @change="updateFilter"
                          clearable
                          :disabled="$apollo.loading"
                        >
                          <el-option
                            v-for="option in tag_filter"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="11">
                      <base-input
                        label="Linkedin Profile (Optional)"
                        :pill="true"
                        class="mb-3"
                        name="Linkedin profile"
                        :rules="{ required: false }"
                        placeholder="https://my-linkedin-profile"
                        v-model="form.linkedin"
                        :disabled="$apollo.loading"
                      >
                      </base-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="11">
                      <base-input
                        label="Github Profile (Optional)"
                        :pill="true"
                        class="mb-3"
                        name="Github profile"
                        :rules="{ required: false }"
                        placeholder="https://my-github-profile"
                        v-model="form.github"
                        :disabled="$apollo.loading"
                      >
                      </base-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="text-right">
                      <base-button
                        type="primary"
                        :pill="true"
                        native-type="submit"
                        class="my-4 btn-success btn-right"
                      >
                        Save
                      </base-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import TagsInput from "@/components/Inputs/TagsInput";
import AutoComplete from "@/components/AutoComplete.vue";
import { Select, Option } from "element-ui";

import {
  GET_HACKATHON_PROFILE,
  GET_PROFILE_SKILL,
  GET_HACKATHON_PROFILE_FROM_USER_ID,
} from "@/graphql/queries";
import {
  CREATE_HACKATHON_PROFILE,
  UPDATE_HACKATHON_PROFILE,
  CREATE_HACKATHON_PROFILE_SKILL,
} from "@/graphql/mutations";

export default {
  name: "MyHackathonProfile",
  components: {
    BaseInput,
    HtmlEditor,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  apollo: {
    // hackathonProfileProfile: {
    //   query: GET_HACKATHON_PROFILE,
    //   result(data) {
    //     this.handleHackathonProfile(data.data.hackathonProfileProfile);
    //   },
    //   error(errors) {
    //     console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
    //     console.log(errors.graphQLErrors);
    //     return false;
    //   },
    //   variables() {
    //     return {
    //       id: this.hackathon_profile_id,
    //     };
    //   },
    //   skip: true,
    // },

    allHackathonProfileProfile: {
      query: GET_HACKATHON_PROFILE_FROM_USER_ID,
      result(data) {
        if (data.data.allHackathonProfileProfile.edges[0] != null) {
          this.handleHackathonProfile(
            data.data.allHackathonProfileProfile.edges[0].node
          );
        }
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      variables() {
        return {
          user: this.user.id_b64,
        };
      },
      skip: true,
    },

    allHackathonProfileSkill: {
      query: GET_PROFILE_SKILL,
      result(data) {
        this.handleHackathonProfileSkills(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
    },
  },
  props: {
    hack_profile_id: {
      value: String,
      description: "The gqlid of the hackathon profile in question",
      default: "",
    },
  },
  data() {
    return {
      user: "",
      hackathon_profile_id: "",
      form: {
        linkedin: "",
        skills: [],
        bio: "",
      },
      tag_filter: [],
    };
  },

  methods: {
    submitForm() {
      let myProm = new Promise((myResolve, myReject) => {
        let profile = utils.deepcopy({
          linkedin: this.form.linkedin,
          skills: this.form.skills,
          bio: this.form.bio,
        });
        myResolve(profile);
      })
        .then((profile) => {
          return new Promise((myResolve, myReject) => {
            this.checkSkills(profile.skills).then(() => {
              myResolve(profile);
            });
          });
        })
        .then((profile) => {
          return new Promise((myResolve, myReject) => {
            this.$apollo.queries.allHackathonProfileSkill.refetch();
            myResolve(profile);
          });
        })
        .then((profile) => {
          setTimeout(() => {
            this.cudSwitch(profile);
            this.$emit("profile-saved");
          }, 1000);
        });
    },
    removeTag(tag) {
      this.form.skills.splice(this.form.skills.indexOf(tag), 1);
    },
    cudSwitch(profile) {
      if (this.hackathon_profile_id) {
        this.updateHackathonProfile(profile);
      } else {
        this.createHackathonProfile(profile);
      }
    },
    updateFilter(x) {
      if (x.length > 0) {
        let size = this.tag_filter.length;
        let skillSize = this.form.skills.length;
        let index = -1;
        let newVal = x[x.length - 1];
        for (let i = 0; i < size; i++) {
          if (this.tag_filter[i].name == newVal) {
            //Used to determiine if the tag filter has a given tag
            return;
          }
          //Accomodates for different cases between entered tag and filter (coding=CoDinG)
          else if (
            this.tag_filter[i].name.toLowerCase() == newVal.toLowerCase()
          ) {
            for (let j = 0; j < skillSize - 1; j++) {
              //If there is already a copy of skill in the skills array, delete that copy, as well as the newly added one,
              //eg 'Coding' in array, entered 'coding', deletes 'Coding' and new 'coding'
              if (
                this.form.skills[j] != null &&
                this.form.skills[j].toLowerCase() == newVal.toLowerCase()
              ) {
                this.form.skills.splice(j, 1);
                this.form.skills.pop();
                index = j; //Sets index to j, shows that a delete was made,
              }
            }
            //If index <0, then a delete wasnt made, and as such update final entery to match the correct format.
            if (index < 0) {
              this.form.skills[skillSize - 1] = this.tag_filter[i].name;
            }
            return;
          }
        }
        //If code reaches here (returns break before), new skill was added and must be added to filter.
        this.tag_filter.push({ name: newVal });
      }
    },
    getUser() {
      this.user = utils.deepcopy(this.$store.getters.getUser);
    },
    async checkSkills(skills) {
      let skillSize = skills.length;
      let filterSize = this.tag_filter.length;
      for (let i = 0; i < skillSize; i++) {
        for (let j = 0; j < filterSize; j++) {
          if (
            this.tag_filter[j].id != null &&
            skills[i] == this.tag_filter[j].name
          ) {
            skills[i] = this.tag_filter[j].id;
          } else if (
            this.tag_filter[j].id == null &&
            skills[i] == this.tag_filter[j].name
          ) {
            await this.createNewSkill(this.tag_filter[j].name, skills, i);
          }
        }
      }
    },
    //Apollo Methods
    handleHackathonProfile(data) {
      this.hackathon_profile_id = data.id;
      this.form.linkedin = data.linkedinUrl;
      this.form.bio = data.bio;
      this.form.skills = [];
      if (data.skills != null) {
        data.skills.edges.forEach((i) => {
          this.form.skills.push(i.node.name);
        });
      }
    },
    handleHackathonProfileSkills(data) {
      this.tag_filter = [];
      this.allHackathonProfileSkill.edges.forEach((i) => {
        this.tag_filter.push(i.node);
      });
    },
    createHackathonProfile(data) {
      this.$apollo
        .mutate({
          mutation: CREATE_HACKATHON_PROFILE,
          variables: {
            user_id: this.user.id_b64,
            linkedinUrl: data.linkedin,
            skills: data.skills,
            bio: data.bio,
          },
        })
        .then((res) => {
          if ("data" in res) {
            this.$emit("updated");
            this.hackathon_profile_id =
              res.data.hackathonProfileCreate.hackathonProfile.id;
          }
        });
    },
    updateHackathonProfile(data) {
      this.$apollo
        .mutate({
          mutation: UPDATE_HACKATHON_PROFILE,
          variables: {
            id: this.hackathon_profile_id,
            user_id: this.user.id_b64,
            linkedinUrl: data.linkedin,
            skills: data.skills,
            bio: data.bio,
          },
        })
        .then((res) => {
          if ("data" in res) {
            this.$emit("updated");
          }
        });
    },
    createNewSkill(newName, skills, index) {
      return this.$apollo
        .mutate({
          mutation: CREATE_HACKATHON_PROFILE_SKILL,
          variables: {
            name: newName,
          },
        })
        .then((res) => {
          return new Promise((myResolve, myReject) => {
            this.$apollo.queries.allHackathonProfileSkill.refetch();
            myResolve(res);
          });
        })
        .then((res) => {
          return new Promise((myResolve, myReject) => {
            // let size = this.tag_filter.length;
            // for (let i = 0; i < size; i++) {
            //   if (
            //     this.tag_filter[i].name ==
            //     res.data.hackathonProfileSkillCreate.skill.name
            //   ) {
            //     this.tag_filter[i].id =
            //       res.data.hackathonProfileSkillCreate.skill.id;
            //     break;
            //   }
            // }
            skills[index] = res.data.hackathonProfileSkillCreate.skill.id;
            myResolve();
          });
        });
    },
    enableQuery() {
      this.$apollo.queries.allHackathonProfileProfile.setOptions({
        fetchPolicy: "network-only",
      });
      if (!this.$apollo.queries.allHackathonProfileProfile.skip) {
        this.$apollo.queries.allHackathonProfileProfile.refetch();
      } else {
        this.$apollo.queries.allHackathonProfileProfile.skip = false;
        this.$apollo.queries.allHackathonProfileProfile.start();
      }
    },
  },
  created() {
    this.getUser();
    if (this.hack_profile_id == "" && this.user != "") {
      this.enableQuery();
    }
  },
  watch: {
    // hack_profile_id() {
    //   if(this.hack_profile_id != "") {
    //     this.hackathon_profile_id = this.hack_profile_id;
    //     this.enableQuery();
    //   }
    // },
  },
};
</script>

<style>
.btn-circle {
  /* width: 35px;
  height: 35px;
  padding: 5px;
  border-radius: 30px;
  font-size: 14px;
  text-align: center;
  line-height: 1.42857; */
  width: 35px;
  height: 35px;
  padding: 0px;
  padding-left: 1px;
  border-radius: 30px;
  font-size: 18px;
  text-align: center;
  line-height: 1.42857;
}

.el-select .el-select__tags .el-tag {
  align-items: center;
  padding: 0.5rem 0.625rem 0.5rem;
  height: 25px;
  margin: 0.125rem;
  border-radius: 10rem;
  line-height: 1.5;
  cursor: pointer;
  transition: all 0.15s ease;
  font-size: 13px;
  background-color: #db3297;
  border-color: #db3297;
  color: #fff;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  font-style: normal;
}

.el-input__inner {
  border-radius: 10rem;
}
</style>
